import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import { brown, deepOrange, grey, red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { CardActionArea, CardMedia, Chip, Fab } from "@material-ui/core";
import NewLineText from "../features/NewLineText";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import OrderOrContinue from "./OrderOrContinue";
import Loader from "../features/Loader";
import MenuItemDetail from "./MenuItemDetail";
import RenderSmoothImage from "render-smooth-image-react";
import "render-smooth-image-react/build/style.css";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import NumberFormat from "react-number-format";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles({
  root: {
    maxWidth: 350,
    borderRadius: "20px",
    height: "100%",
    // background: grey[100]
    backgroundColor: "transparent"
  },
  cardActionArea: {
    "&:hover $focusHighlight": {
      opacity: 0
    }
  },
  focusHighlight: {},
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    height: 180
  },
  expandOpen: {},
  avatar: {
    backgroundColor: red[500]
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 50%"
  }
});

const MenuList = ({ menu_list }) => {
  const classes = useStyles();
  const [expandedId, setExpandedId] = React.useState(-1);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null);
  const [menuItemDialogOpen, setMenuItemDialogOpen] = useState(false);

  const [openOrderOrContinue, setOpenOrderOrContinue] = useState(false);

  const handleExpandClick = (i) => {
    setExpandedId(expandedId === i ? -1 : i);
  };

  const onMenuItemSelect = (menuItem) => {
    setSelectedMenuItem(menuItem);
    setMenuItemDialogOpen(true);
  };

  const onMenuItemUnselect = () => {
    setMenuItemDialogOpen(false);
    setSelectedMenuItem(null);
  };

  const handleMenuItemSubmit = () => {
    onMenuItemUnselect();
    setOpenOrderOrContinue(true);
  };

  if (!menu_list) {
    return <Loader />;
  }
  //Category Filter
  const [filterCategory, setFilterCategory] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState(false);

  const onFilter = (filterCategory) => {
    setFilterCategory(filterCategory);
    setFilterEnabled(true);
  };
  const offFilter = () => {
    setFilterEnabled(false);
  };
  const uniqueCategories = [];
  console.log(uniqueCategories);
  menu_list.map(item => {
    if (uniqueCategories.indexOf(item.category.title) === -1) {
      uniqueCategories.push(item.category.title);
    }
  });

  //TABS
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        style={{ marginBottom: 20 }}
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        scrollButtons="on"
        textColor="secondary"
        variant="scrollable"
        // scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {filterEnabled === true &&
          <Tab style={{ fontSize: 10, color: deepOrange[600] }} label="сбросить фильтр" {...a11yProps(-1)}
               onClick={() => offFilter()} />
        }
        {filterEnabled === false &&
        <Tab style={{ fontSize: 10,}} label="все товары" {...a11yProps(-1)}
             onClick={() => offFilter()} />
        }
        {uniqueCategories.map((item, index) => (
          <Tab style={{fontSize: 8.5, fontWeight: "bold"}} label={item} {...a11yProps(index)} onClick={() => onFilter(item)} />
        ))}
      </Tabs>
      <Container className={classes.cardGrid} maxWidth="md">
        <Grid container spacing={5}>
          {(filterEnabled ? menu_list.filter(toFilterItem => toFilterItem.category.title === filterCategory) : menu_list).map(item => (
            <Grid key={item.title} item xs={12} sm={4} md={4}>
              <Card className={classes.root}>
                <CardActionArea
                  classes={{
                    root: classes.cardActionArea,
                    focusHighlight: classes.focusHighlight
                  }}
                  onClick={() => onMenuItemSelect(item)}
                >
                  {/*todo delete if RenderSmoothImage is ok */}
                  {/*<CardMedia className={classes.media} image={item.photo} title={item.title} />*/}
                  <div className={classes.media}>
                    <div className={classes.imageSrc}>
                      <RenderSmoothImage
                        objectFit={"none"}
                        src={item.photo}
                      />
                    </div>
                  </div>
                  {/*<div style={{textAlign: "center"}}>*/}
                  <Fab
                    disableFocusRipple={false}
                    disableRipple={true}
                    style={{
                      bottom: 143,
                      opacity: 0.3,
                      marginLeft: 5,
                      borderRadius: 7,
                      // height: 25
                    }}
                    color={"secondary"}
                    variant={"extended"}
                    size={"small"}
                  >
                    <Typography style={{fontSize: 7, fontWeight: "bold"}} variant={"caption"}>
                      {item.category.title}
                    </Typography>
                  </Fab>
                  {/*</div>*/}
                </CardActionArea>
                <CardContent style={{ marginTop: -10, marginBottom: -20 }}>
                  <Typography style={{ fontWeight: "bold" }} variant="caption" color="textSecondary">
                    {item.title}
                  </Typography>
                </CardContent>

                <CardActions>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexGrow: 1,
                      marginLeft: 8
                    }}
                  >
                    <Typography color="textSecondary" display={"inline"}>
                      {/*  {item.portion_size?.portions[0]?.price ? item.portion_size.portions[0]?.price + "₽" : ""}*/}
                      <NumberFormat
                        value={item.portion_size?.portions[0]?.price ? item.portion_size.portions[0]?.price : ""}
                        displayType={"text"} thousandSeparator={true}
                        suffix={"₽"}
                      />
                    </Typography>


                    {/*<IconButton onClick={() => handleExpandClick(i)}>*/}
                    {/*  {expandedId !== i ? <ExpandMoreIcon /> : <ExpandLessIcon />}*/}
                    {/*</IconButton>*/}
                    <IconButton onClick={() => onMenuItemSelect(item)}>
                      <ShoppingBasketIcon />
                    </IconButton>
                  </div>
                </CardActions>
                {/*<Collapse in={expandedId === i} timeout="auto" unmountOnExit>*/}
                <CardContent>
                  <Typography variant={"subtitle2"} color={"textSecondary"} paragraph>
                    <NewLineText text={item.short_description} />
                  </Typography>
                  <Typography variant={"subtitle2"} color={"textSecondary"} paragraph>
                    <NewLineText text={item.consist} />
                  </Typography>
                </CardContent>
                {/*</Collapse>*/}
              </Card>
            </Grid>
          ))}
        </Grid>

        {menuItemDialogOpen && selectedMenuItem && (
          <MenuItemDetail
            open={menuItemDialogOpen}
            menuItem={selectedMenuItem}
            onClose={onMenuItemUnselect}
            onSendToCart={handleMenuItemSubmit}
          />
        )}

        <OrderOrContinue isOpen={openOrderOrContinue} onClose={() => setOpenOrderOrContinue(false)} />
      </Container>
    </div>
  );
};

export default MenuList;
