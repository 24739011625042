import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Loader from "./features/Loader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     width: "100%",
//     backgroundColor: theme.palette.background.paper
//   }
// }));

const Filter = ({ menu_list }) => {
  // const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!menu_list) {
    return <Loader />;
  }
  //Category Filter
  const [filterCategory, setFilterCategory] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState(false);

  const onClick = (filterCategory) => {
    setFilterCategory(filterCategory);
    setFilterEnabled(true);
  };

  const uniqueCategories = [];
  console.log(uniqueCategories);
  menu_list.map(item => {
    if (uniqueCategories.indexOf(item.category.title) === -1) {
      uniqueCategories.push(item.category.title);
    }
  });


  return (
    <div
      // className={classes.root}
    >
      <Tabs
        style={{ marginBottom: 20 }}
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="secondary"
        variant="scrollable"
        scrollButtons="on"
        aria-label="scrollable auto tabs example"
      >
        {uniqueCategories.map((item, index) => (
          <Tab label={item} {...a11yProps(index)} onClick={() => onClick(item)} />
        ))}
      </Tabs>
      <div>
        {(filterEnabled ? menu_list.filter(toFilterItem => toFilterItem.category.title === filterCategory) : menu_list).map(item => (
          <div>
            {item.title} {item.id}
          </div>
        ))}
      </div>
    </div>
  );
};
export default Filter;