import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles({
    paperWidthXs: {
        minWidth: "310px",
        margin: 5,
    },
    // paperScrollPaper: {
    //     maxHeight: "calc(100% - 5px)",
    // },
    closeBtn: {
        float: "right",
    },
    dialogTitle: {
        paddingTop: 0,
        paddingBottom: 0,
        textAlign: "center",
    },
    singleAction: {},
    actionsSpaceBetween: {
        display: "flex",
        justifyContent: "space-between",
    },
});

const Modal = ({ open, title, content, actions, onClose, maxWidth, fullWidth, scroll, fullscreen }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <Dialog
            key={title}
            classes={{
                paperWidthXs: classes.paperWidthXs,
            }}
            open={open ?? false}
            onClose={onClose}
            maxWidth={maxWidth ? maxWidth : "xs"}
            fullWidth={fullWidth ? fullWidth : false}
            scroll={scroll ? scroll : "paper"}
            fullScreen={matches && fullscreen ? fullscreen : false}
        >
            <div className={classes.closeBtnDiv}>
                <IconButton className={classes.closeBtn} onClick={onClose} disableRipple>
                    <CloseIcon />
                </IconButton>
            </div>
            {title && (
                <DialogTitle className={classes.dialogTitle} disableTypography>
                    <Typography color={"textSecondary"} variant={"caption"}>
                    {title}
                    </Typography>
                </DialogTitle>
            )}
            {content && <DialogContent>{content}</DialogContent>}
            {actions && (
                <DialogActions className={clsx(actions.length > 1 && classes.actionsSpaceBetween)}>
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default Modal;
