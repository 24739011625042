import React, { useEffect, useState } from "react";
import { Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import Modal from "../features/Modal";
import QuantityOutlinedDiv from "../features/QuantityOutlinedDiv";
import SelectFromList from "./additional_select";
import AdditiveList from "./AdditiveList";
import { addIntoCart } from "../../actions/cartActions";
import NumberFormat from "react-number-format";

const MenuItemDetail = ({ open, menuItem, onSendToCart, onClose }) => {
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(menuItem.portion_size.portions[0]?.price ?? 0);
  const [portion, setPortion] = useState(menuItem.portion_size.portions[0] ?? { size: "", price: 0 });
  const [additives, setAdditives] = useState([]);
  const [modifiers, setModifiers] = useState([]);
  const [errors, setErrors] = useState({ modifiers: null });
  const [hasErrors, setHasErrors] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const calculatePrice = () => {
      let totalPrice = portion.price;
      if (additives.length) {
        totalPrice = additives.reduce((t, c) => t + c.price, totalPrice);
      }
      if (modifiers.length) {
        totalPrice += modifiers.reduce((t, c) => t + c.item.price, 0);
      }
      setTotalPrice(totalPrice * quantity);
    };
    if (open) {
      calculatePrice();
    }
  }, [additives, modifiers, open, portion.price, quantity]);

  useEffect(() => {
    const focusOnError = () => {
      if (hasErrors) {
        const firstErrorKey = Object.keys(errors)[0];
        if (global.window.document.getElementsByName(firstErrorKey).length) {
          global.window.document.getElementsByName(firstErrorKey)[0].focus();
        }
      }
    };
    if (hasErrors) {
      focusOnError();
    }
  }, [hasErrors, errors]);

  const onModifierSelect = (modifier) => {
    const index = Object.keys(modifier)[0];
    setModifiers([...modifiers.filter((m) => m.title !== modifier[index].title), modifier[index]]);
    setErrors({ ...errors, modifiers: { ...errors.modifiers, [index]: "" } });
  };

  const formValid = () => {
    if (menuItem.select_additive.length === 0 && !hasErrors && menuItem.select_additive.length === modifiers.length)
      return true;

    let errorCount = 0;
    const modifierErrors = {};
    for (let i = 0; i < menuItem.select_additive.length; i++) {
      const modifier = modifiers.find((m) => m.title === menuItem.select_additive[i].title);
      if (modifier === undefined) {
        errorCount++;
        setHasErrors(true);
        const m = menuItem.select_additive.find((a, index) => index === i);
        modifierErrors[i] = `Выберите ${m.title.toLowerCase()}`;
      }
    }
    setErrors({ ...errors, modifiers: modifierErrors });
    return errorCount === 0;
  };

  const onSubmit = () => {
    if (formValid()) {
      dispatch(
        addIntoCart({
          id: menuItem.id,
          title: menuItem.title,
          photo: menuItem.photo,
          portion,
          additives,
          modifiers,
          quantity,
          totalPrice
        })
      );
      onSendToCart();
    }
  };

  const onCloseMenuItem = () => {
    setModifiers([]);
    setAdditives([]);
    setErrors({ modifiers: null });
    setHasErrors(false);
    onClose();
  };

  const renderedContent = () => {
    return (
      <React.Fragment>
        {/*{menuItem.portion_size.portions.length === 1 && (*/}
        {/*  <Typography style={{ color: "gray" }} variant={"body2"}>*/}
        {/*    {portion.size} {menuItem.portion_size.unit}*/}
        {/*  </Typography>*/}
        {/*)}*/}
        {menuItem.portion_size.portions.length > 1 && (
          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor={"portion"}>Порция</InputLabel>
            <Select
              name={"portion"}
              value={portion.size}
              onChange={(e) =>
                setPortion(menuItem.portion_size.portions.filter((item) => item.size === e.target.value)[0])
              }
              renderValue={(value) => <span>{value} {menuItem.portion_size.unit}</span>}
            >
              {menuItem.portion_size.portions.map((portionItem) => (
                <MenuItem
                  key={portionItem.size}
                  value={portionItem.size}
                  name={portionItem.size}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>
                    {portionItem.size} {menuItem.portion_size.unit}
                  </span>
                  {/*<span style={{ color: "gray" }}>{portionItem.price}₽</span>*/}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {menuItem.select_additive.map((additiveList, i) => (
          <SelectFromList
            key={additiveList.id}
            id={additiveList.id}
            title={additiveList.title}
            options={additiveList.select_items}
            index={i}
            onSelect={onModifierSelect}
            errors={errors.modifiers === null ? "" : errors.modifiers[i]}
          />
        ))}
        <AdditiveList additives={menuItem.additive} onChange={setAdditives} />
      </React.Fragment>
    );
  };

  const renderActions = () => {
    return [
      <QuantityOutlinedDiv
        key={"quantity"}
        quantity={quantity}
        onIncrement={() => setQuantity(quantity + 1)}
        onDecrement={() => (quantity > 1 ? setQuantity(quantity - 1) : null)}
      />,
      <span style={{ fontSize: "1.1rem" }} key={"price"}>
        <Typography>
          <NumberFormat
            value={totalPrice ? totalPrice : ""}
            displayType={"text"} thousandSeparator={true}
            suffix={"₽"}
          />
        </Typography>
        {/*{totalPrice ? totalPrice + "₽" : ""}*/}
      </span>,
      <Button key="addToCartBtn" variant={"contained"} color={"secondary"} onClick={onSubmit}>
        Добавить
      </Button>
    ];
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onCloseMenuItem}
        title={menuItem.title}
        content={renderedContent()}
        actions={renderActions()}
      />
    </React.Fragment>
  );
};

export default MenuItemDetail;
