import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCartItems } from "../../actions/cartActions";
import { makeStyles, Paper, Typography } from "@material-ui/core";
import CartItem from "./CartItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Loader from "../features/Loader";
import QuantityDiv from "../features/QuantityDiv";
import Modal from "../features/Modal";
import OrderForm from "../order/OrderForm";
import { getWorkDay } from "../../utils/restaurantUtils";
import { saveAddress, tryRegisterUser } from "../../actions/userActions";
import { saveCurrentOrder } from "../../actions/orderActions";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  controls: {
    textAlign: "right",
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  totalPrice: {
    fontSize: "1.1rem",
    padding: "0px 2px",
  },
  promoCodeMessage: {
    marginRight: theme.spacing(2),
  },
}));

const Cart = ({ restaurant, location }) => {
  const classes = useStyles();
  const { user } = useSelector((state) => state);
  const { cart } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [tableware, setTableware] = useState(1);
  const [error, setError] = useState({
    open: false,
    title: null,
    message: null,
  });
  const [orderMode, setOrderMode] = useState(false);

  const getErrors = useCallback(() => {
    try {
      if (location.state?.error) {
        setError({
          open: true,
          title: <ErrorOutlineIcon fontSize={"large"} style={{ color: "error" }} />,
          message: location.state.error.message,
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [location.state]);

  useEffect(() => {
    dispatch(getCartItems());
    getErrors();
    setLoading(false);
  }, [dispatch, getErrors]);

  const onOrderSubmit = async (values) => {
    setLoading(true);

    const addressInfo = {
      name: values.name,
      phone: values.phone,
      instagram: values.instagram,
      comment: values.comment,
    };

    addressInfo.street = values.dadata.data?.street_with_type ?? "";
    addressInfo.streetBuilding = values.dadata.data?.house ?? "";
    addressInfo.building = values.dadata.data?.block ?? "";
    addressInfo.apartment = values.dadata.data?.flat ?? values.apartment;
    addressInfo.entrance = values.entrance;
    addressInfo.doorCode = values.doorCode;
    addressInfo.floor = values.floor;
    addressInfo.lat = values.dadata.data?.geo_lat ?? "";
    addressInfo.long = values.dadata.data?.geo_lon ?? "";
    addressInfo.address_value = values.dadata.value ?? "";

    saveAddress(addressInfo, values.delivery);

    let result = { success: false };
    if (!user.registered) {
      result = await dispatch(
        tryRegisterUser({ phone: values.phone, instagram: addressInfo.instagram, name: values.name })
      );
    } else {
      result.success = true;
    }

    if (!result.success) {
      setOrderMode(false);
      setError({
        open: true,
        message: "Что-то пошло не так. Попробуйте повторить позднее",
      });
      return;
    }

    if (cart.promoCodes) {
      try {
        addressInfo.promocode = { name: Object.keys(cart.promoCodes)[0], value: Object.values(cart.promoCodes)[0] };
      } catch (e) {
        console.log(e);
      }
    }

    setOrderMode(false);
    await dispatch(
      saveCurrentOrder({
        title: values.phone,
        contact_information: addressInfo,
        order: cart.items,
        order_price: cart.totalPrice,
        delivery: values.delivery,
        delivery_price: values.deliveryPrice,
        total_price: cart.totalPrice + values.deliveryPrice,
        tableware: tableware,
        table: values.table ?? 0,
        payment_method: "card",
      })
    );
    setLoading(false);
  };

  const onCartSubmit = async () => {
    const currWorkDay = await getWorkDay(restaurant.working_hours);
    if (currWorkDay.open) {
      setOrderMode(true);
    } else {
      const nextStartWorkTime = {
        hours: currWorkDay.next_start_time ? currWorkDay.next_start_time.getHours() : null,
        minutes: currWorkDay.next_start_time
          ? currWorkDay.next_start_time.getMinutes().toString().padStart(2, "0")
          : null,
      };

      if (nextStartWorkTime.hours) {
        setError({
          open: true,
          message: `магазин откроется в ${nextStartWorkTime.hours}:${nextStartWorkTime.minutes}`,
        });
      } else {
        setError({
          open: true,
          message: `магазин не принимает заказы в данный момент`,
        });
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  if (cart.items.length <= 0) {
    return <Typography>Корзина пуста</Typography>;
  }

  return (
    <React.Fragment>
      <OrderForm
        open={orderMode}
        price={cart.totalPrice}
        restaurant={restaurant}
        onClose={() => setOrderMode(false)}
        onSubmit={onOrderSubmit}
      />
      <Paper>
        <div style={{ padding: "1em" }}>
          {cart.items.map((cartItem) => (
            <CartItem
              key={
                cartItem.id +
                cartItem.additives?.reduce((t, c) => t + c.id, "") +
                cartItem.modifiers?.reduce((t, c) => t + c.item.id, "")
              }
              cartItem={cartItem}
            />
          ))}
        </div>

        <div className={classes.controls}>
          <Typography className={classes.totalPrice}>
              <NumberFormat
                value={cart.totalPrice}
                displayType={"text"} thousandSeparator={true}
                prefix={"Итого: "}
                suffix={"₽"}
              />
          </Typography>
        </div>

        <Grid container justifyContent={"flex-end"}>
          {/*<Grid item md={5} style={{ display: "flex", alignItems: "center", margin: 10 }}>*/}
          {/*  <QuantityDiv*/}
          {/*    quantity={tableware}*/}
          {/*    onDecrement={() => (tableware > 0 ? setTableware(tableware - 1) : null)}*/}
          {/*    onIncrement={() => setTableware(tableware + 1)}*/}
          {/*  />*/}
          {/*  &nbsp;*/}
          {/*  <*/}
          {/*    RestaurantIcon />*/}
          {/*</Grid>*/}

          <Grid item md={"auto"} style={{ display: "flex", alignItems: "center", marginRight: "1em", marginBottom: 20 }}>
            <Button variant={"contained"} color={"secondary"} onClick={onCartSubmit}>
              Заказать
            </Button>
          </Grid>
        </Grid>

        {error.open && (
          <Modal
            open={error.open}
            onClose={() => setError({ open: false })}
            title={error.title}
            content={error.message}
            actions={
              <Button color='secondary' onClick={() => setError({ open: false })} disableFocusRipple>
                Ок
              </Button>
            }
          />
        )}
      </Paper>
    </React.Fragment>
  );
};

export default Cart;
