import React, { useEffect, useState } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "../css/order.css";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { green } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {},
  col2: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    listStyle: "outside none none",
    paddingLeft: "20px",
    alignItems: "center",
    justifyContent: "center",
  },

  checkIcon: {
    borderRadius: 9,
    width: 19,
    height: 19,
    boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    // backgroundColor: '#f5f8fa',
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      // backgroundColor: '#ebf1f5',
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: green[900],
    backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 19,
      height: 19,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: green[900],
    },
  },
});

const AdditiveList = ({ additives, onChange }) => {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    onChange(checked);
  }, [checked, onChange]);

  const onCheck = (value) => {
    setChecked(checked.includes(value) ? checked.filter((item) => item !== value) : [...checked, value]);
  };

  return (
    <React.Fragment>
      <ul className={classes.col2}>
        {additives.map((additive) => {
          return (
            <li key={additive.id}>
              <FormControlLabel
                name={"additive"}
                label={
                  <Typography style={{ textAlign: "left" }} variant={"subtitle2"} color={"textSecondary"}>
                    {additive.title}
                    <hr />
                  </Typography>
                }
                control={
                  <Checkbox
                    onChange={() => onCheck(additive)}
                    checkedIcon={<span className={clsx(classes.checkIcon, classes.checkedIcon)} />}
                    icon={<span className={classes.checkIcon} />}
                  />
                }
              />
            </li>
          );
        })}
      </ul>
    </React.Fragment>
  );
};

export default AdditiveList;
