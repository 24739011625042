import React, { useEffect, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import HistoryIcon from "@material-ui/icons/History";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import { Container, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";
import Badge from "@material-ui/core/Badge";
import { Link } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import "../css/drawer.css";
import { getCartItems } from "../../actions/cartActions";
import NavListItem from "../features/NavListItem";

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  appBar: {
    marginBottom: theme.spacing(2),
    background: "radial-gradient(circle, rgba(47,56,59,1) 0%, rgba(52,60,62,1) 100%)",
    color: "#cccccc; text - decoration:none; font - weight:normal",
  },
  toolbar: {
    padding: 0,
    display: "flex",
    justifyContent: "space-between",
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },

  bar_title: {
    align: "right",
  },
  paymentButton: {
    color: "inherit",
  },
  iconBtn: {
    color: "inherit",
  },
}));

const Drawer = () => {
  const classes = useStyles();
  const anchor = "left";
  const [state, setState] = useState({ [anchor]: false, cartCount: 0 });
  const cartItemsCount = useSelector((state) => state.cart.count);
  const orgTitle = useSelector((state) => state.organization.title);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCartItems());
  }, [dispatch]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={classes.list}
      role='presentation'
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <NavListItem icon={<MenuBookIcon />} title={"Меню"} to={"/"} />
        <NavListItem icon={<HourglassEmptyIcon />} title={"Статус"} to={"/order_status"} />
        <NavListItem icon={<HistoryIcon />} title={"История"} to={"/order_history"} />
        <Divider />
        <NavListItem icon={<ContactSupportIcon />} title={"Контакты"} to={"/contacts"} />
      </List>
    </div>
  );

  return (
    <React.Fragment>
      <AppBar className={classes.appBar} position={"relative"}>
        <Container disableGutters maxWidth={"xl"}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              disableRipple
              className={classes.iconBtn}
              aria-label={"open drawer"}
              onClick={toggleDrawer(anchor, true)}
            >
              <MenuIcon />
            </IconButton>
            <div style={{display: "flex", alignItems: "center"}}>
              <Typography style={{ fontSize: 20, marginLeft: "1em", marginRight: "1em" }} variant='caption' noWrap>
                {orgTitle}
              </Typography>
              <IconButton disableRipple className={clsx(classes.iconBtn)} component={Link} to={"/cart"}>
                <Badge badgeContent={cartItemsCount} color={"secondary"}>
                  <ShoppingBasketIcon />
                </Badge>
              </IconButton>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <SwipeableDrawer
        open={state[anchor]}
        onOpen={toggleDrawer(anchor, true)}
        onClose={toggleDrawer(anchor, false)}
        anchor={anchor}
      >
        {list(anchor)}
      </SwipeableDrawer>
    </React.Fragment>
  );
};

export default Drawer;
